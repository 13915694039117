/* Define the progress bar container */
.progress-container {
  width: 100%;
  padding: 10px;
}

/* Define the progress bars with different widths */
.fitness-bar {
  width: 80%; /* Adjust the width for fitness */
  height: 20px;
  background-color: lightblue;
  margin: 10px auto;
}

.nutrition-bar {
  width: 60%; /* Adjust the width for nutrition */
  height: 20px;
  background-color: lightblue;
  margin: 10px auto;
}

.coaching-bar {
  width: 40%; /* Adjust the width for coaching */
  height: 20px;
  background-color: lightblue;
  margin: 10px auto;
}

.therapy-bar {
  width: 70%; /* Adjust the width for therapy */
  height: 20px;
  background-color: lightblue;
  margin: 10px auto;
}

/* Define category labels */
.category-label {
  font-weight: bold;
}
.containerr {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* text-align: center; */
}

.word {
  font-size: 18px;
  margin-bottom: 10px;
}

.number {
  font-size: 38px;
  color: #5e95ff;
}

.percentage {
  font-size: 38px;
  color: #5e95ff;
}
.signno {
  color: #5e95ff;
  font-size: 38px;
}
.progress-container {
  display: flex;
  align-items: center;
}
.progress-label {
  font-weight: bold;
  margin-right: 25px;
}
.progress {
  flex: 3;

  position: relative;
}
.checked {
  color: #5e95ff;
}
.unchecked {
  color: white;
  border-color: #5e95ff;
}
.sidenav-container {
  width: 250px; /* Adjust the width of the side navbar as needed */
  float: left;
  position: fixed;
  height: 100%;
}

/* Add CSS for the content container */
.content-container {
  margin-left: 30px; /* Adjust the margin to create space between the two containers */
  padding: 20px; /* Add some padding to the content */
}

/* Ensure the content doesn't flow behind the navbar */

.company-logo {
  position: absolute;
  top: 6px;
  right: 20px;
  display: flex;
  align-items: center;
}
.sidenav-footer {
  position: absolute;
  bottom: 0;
  width: 76%;
  margin-bottom: 29px;
}
/* Add this CSS to style the Y-axis label */
.y-axis-label {
  position: absolute;
  top: 50%;
  left: -56.3px; /* Adjust the position as needed */
  transform: translateY(-50%) rotate(-90deg);
  transform-origin: center center;
  text-align: center;
}

.rotate-label {
  font-weight: bold;
  font-size: 10px;
}
.chart-container {
  margin-right: 30%;
}
.progress-bar {
  background-color: #5e95ff;
}
.spinner{
    color: #5e95ff;
}