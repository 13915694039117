.dropdown-card {
  position: absolute;
  top: 100%; /* Position below the text */
  left: 29%;
  width: 129px; 
  z-index: 1; /* Display on top of other content */
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Add a shadow effect */
}

.card {
  padding: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  padding: 10px;
  cursor: pointer;
}


