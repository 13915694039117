.getQuote_getQuoteForm__1DMlx {
  padding-top: 10rem;
  margin-bottom: 5rem;
  position: relative;
  background-color: var(--c-white);
  
}

@media screen and (max-width: 767px) {
  .getQuote_getQuoteForm__1DMlx {
    padding-top: 0px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 1023px) {
  .getQuote_getQuoteForm__1DMlx {
    margin-bottom: 0px;
  }
}

.getQuote_getQuoteForm__1DMlx::before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60%;
  background-image: var(--c-gradient);
}

.getQuote_getQuoteForm__1DMlx .container {
  max-width: 548px;
  background-color: var(--c-white);
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(188, 199, 255, 0.3) 0px 20px 20px;
  padding: 5rem 0px;
}

@media screen and (max-width: 1127px) {
  .getQuote_getQuoteForm__1DMlx .container {
    border-radius: 0px;
  }
}

.getQuote_getQuoteForm__1DMlx form {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 3;
}

@media screen and (max-width: 767px) {
  .getQuote_getQuoteForm__1DMlx form {
    max-width: 343px;
    padding: 1.5rem 1rem;
    border-radius: 0.5rem;
    background: var(--c-white);
  }
}

.getQuote_getQuoteForm__1DMlx form input {
  width: 100%;
}

.getQuote_getQuoteForm__1DMlx h3 {
  text-align: center;
}

.getQuote_getQuoteForm__1DMlx .leaf {
  position: absolute;
  bottom: -11rem;
  right: -12rem;
}

.getQuote_getQuoteForm__1DMlx.getQuote_success__1T_Gf h3 {
  margin-bottom: 2rem;
}

.getQuote_getQuoteForm__1DMlx::before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60%;
  background-image: var(--c-gradient);
}

.getQuote_getQuoteForm__1DMlx .container {
  background-color: var(--c-white);
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(188, 199, 255, 0.3) 0px 20px 20px;
  padding: 5rem 0px;
}
.container {
  margin-left: auto;
  margin-right: auto;
}
* {
  box-sizing: border-box;
}



:root {
  --c-primary: #4127dc;
  --c-secondary: #10be0d;
  --c-tertiary: #ff9fab;
  --c-gradient: linear-gradient(
    90deg,
    rgba(255, 193, 193, 0.4) 11.96%,
    rgba(243, 193, 255, 0.38) 77.29%
  );
  --c-black: #000;
  --c-grey-50: #262626;
  --c-grey-40: #595959;
  --c-grey-30: #8c8c8c;
  --c-grey-20: #bfbfbf;
  --c-grey-10: #f2f2f2;
  --c-white: #fff;
  --f-primary: "Montserrat", sans-serif;
  --f-secondary: "Epilogue", sans-serif;
}

.signupbtn {
  border: "2px solid #3c2f61";
  color: white;
  background-color: #3c2f61;
}
.signupbtn:hover {
  border: "2px solid #3c2f61" !important;
  color: white !important;
  background-color: #3c2f61 !important;
}

